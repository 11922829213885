import React, { useState ,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { referAndEarnService } from "../../api/services/ReferAndEarnService";
import { ReactComponent as Exit } from "../../assets/header/Exit.svg";
import { useSelector } from "react-redux";

const MobileReferEarn = ({ onRequestClose }) => {
  const [formData, setFormData] = useState({
    referrer_name: "",
    referrer_number: "",
    upi_id: "",
    city: "",
    student_name: "",
    student_number: "",
    student_city: "",
  });

  

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const clearFields = () => {
    setFormData({
      referrer_name: "",
      referrer_number: "",
      upi_id: "",
      city: "",
      student_name: "",
      student_number: "",
      student_city: "",
    });
  };
  const userData = useSelector((state) => state.auth);

  useEffect(()=>{
  
    if (userData?.registration_id) {
      setFormData((prevData) => ({
        ...prevData,
        referrer_name: userData.name || "",
        referrer_number: userData.mobilenumber || "",
      }));
    } else {
      console.log("No registration_id found for the user.");
    }
  
  },[userData?.registration_id])

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true);
    setError(null);
    try {
      const response = await referAndEarnService(formData);

      toast.success("Referral submitted successfully!");
      clearFields(); // Clear all input fields
      setTimeout(() => {
        onRequestClose(); // Close the modal after a delay
      }, 2000); // 2-second delay
      setLoading(false);
    } catch (err) {
      console.error("Error:", err);
      setError("Failed to submit. Please try again.");
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  const handleNavigation = (program) => {
    const programSlug = program.toLowerCase().replace(/\s+/g, "-"); // Convert program name to slug
    navigate(`/filter/${programSlug}-colleges-in-india`); // Pass program name as state
  };

  return (
    <div className='fixed inset-0 p-0 flex flex-col overflow-y-auto justify-center content-center  z-50 bg-white lg:hidden '>
      {/* Left Section */}

      <div className='flex-1 bg-[#F8F8F8] p-8 md:p-6'>
        <h2 className='text-lg font-bold mt-6 text-center'>Referrer Details</h2>
        <button
          className='absolute top-8 right-3 flex lg:hidden text-white text-2xl font-bold'
          onClick={onRequestClose}
        >
          <Exit className='h-[20px] w-[20px]' />
        </button>
        <div className='space-y-4'>
          <div>
            <label className='block text-sm font-medium mb-1'>
              Referrer Name
            </label>
            <input
              type='text'
              name='referrer_name'
              value={formData.referrer_name}
              onChange={handleChange}
              placeholder='Enter Full Name'
              className='w-full border border-gray-300 rounded-lg  px-2 py-1 focus:outline-none focus:ring focus:ring-[#3ACA2E]'
              required
            />
          </div>
          <div>
            <label className='block text-sm font-medium mb-1'>
              Referrer Contact Number
            </label>
            <input
              type='tel'
              name='referrer_number'
              value={formData.referrer_number}
              onChange={handleChange}
              placeholder='Enter Phone Number'
              className='w-full border border-gray-300 rounded-lg px-2 py-1 focus:outline-none focus:ring focus:ring-[#3ACA2E]'
              required
            />
          </div>
          <div>
            <label className='block text-sm font-medium mb-1'>UPI ID</label>
            <input
              type='text'
              name='upi_id'
              value={formData.upi_id}
              onChange={handleChange}
              placeholder='Enter UPI ID'
              className='w-full border border-gray-300 rounded-lg px-2 py-1 py-1focus:outline-none focus:ring focus:ring-[#3ACA2E]'
              required
            />
          </div>
          <div>
            <label className='block text-sm font-medium mb-1'>City</label>
            <input
              type='text'
              name='city'
              value={formData.city}
              onChange={handleChange}
              placeholder='Enter City Name'
              className='w-full border border-gray-300 rounded-lg  px-2 py-1 focus:outline-none focus:ring focus:ring-[#3ACA2E]'
              required
            />
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className='flex-1 bg-[#004D92] p-4 md:p-6 text-white relative'>
        <button
          className='absolute top-2 hidden lg:flex right-3 text-white text-2xl font-bold'
          onClick={onRequestClose}
        >
          <Exit className='h-[20px] w-[20px]' />
        </button>
        <h2 className='text-lg font-bold mb-4 text-center'>Student Details</h2>
        <div className='space-y-4'>
          <div>
            <label className='block text-sm font-medium mb-1'>
              Student Name
            </label>
            <input
              type='text'
              name='student_name'
              value={formData.student_name}
              onChange={handleChange}
              placeholder='Enter Student Name'
              className='w-full border border-gray-300 rounded-lg  px-2 py-1 focus:outline-none focus:ring focus:ring-[#3ACA2E] text-black'
              required
            />
          </div>
          <div>
            <label className='block text-sm font-medium mb-1'>
              Student Contact Number
            </label>
            <input
              type='tel'
              name='student_number'
              value={formData.student_number}
              onChange={handleChange}
              placeholder='Enter Phone Number'
              className='w-full border border-gray-300 rounded-lg px-2 py-1 focus:outline-none focus:ring focus:ring-[#3ACA2E] text-black'
              required
            />
          </div>
          <div>
            <label className='block text-sm font-medium mb-1'>
              Student City
            </label>
            <input
              type='text'
              name='student_city'
              value={formData.student_city}
              onChange={handleChange}
              placeholder='Enter Student City'
              className='w-full border border-gray-300 rounded-lg px-2 py-1 py-1focus:outline-none focus:ring focus:ring-[#3ACA2E] text-black'
              required
            />
          </div>
          <div className='flex items-center justify-center'>
            <button
              type='submit'
              onClick={handleSubmit}
              disabled={loading}
              className={`w-full md:w-1/2 bg-[#3ACA2E] ${
                loading ? "opacity-50 cursor-not-allowed" : "hover:bg-green-600"
              } text-white font-semibold py-2 border-[1px] rounded-3xl mt-4`}
            >
              {loading ? "Submitting..." : "SUBMIT"}
            </button>
          </div>
          {error && <p className='text-red-500 text-center mt-2'>{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default MobileReferEarn;